<template>
  <div class="section-wrapper">
    <!-- <div class="breadcrumb-wrapper">
      <b-breadcrumb class="custom-bread"></b-breadcrumb>
    </div> -->
    <b-breadcrumb>
      <b-breadcrumb-item to="/dashboard">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <!-- <b-breadcrumb-item to="/user">Manage Book</b-breadcrumb-item> -->
      <b-breadcrumb-item active>Invoice</b-breadcrumb-item>
    </b-breadcrumb>
      <div class="form-wrapper">
      <b-card title="Invoice Search">
          <b-card-text>
              <b-row style="font-size: 13px;">
                 <b-col lg="3" md="3" sm="3" xs="6">
                   <b-form-group
                    label-for="payment_date">
                    <template v-slot:label>
                      Payment Date
                     </template>
                    <flat-pickr
                    id="payment_date"
                    v-model="search.payment_date"
                    class="form-control"
                    placeholder="select Date"
                    :config="flatPickrConfig"
                    />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="6">
                <b-form-group
                 label-for="subcription_plan_id"
                >
                <template v-slot:label>
                 Subscription Plan
                </template>
                <b-form-select
                    plain
                    v-model="search.subcription_plan_id"
                    :options="subscriptionPlanList"
                    id="subcription_plan_id"
                   >
                  <template v-slot:first>
                   <b-form-select-option :value=0>Select</b-form-select-option>
                 </template>
                </b-form-select>
              </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="6">
                <b-form-group
                 label-for="payment_media_id"
                >
                <template v-slot:label>
                 Payment Media
                </template>
                <b-form-select
                    plain
                    v-model="search.payment_media_id"
                    :options="paymentMediaList"
                    id="payment_media_id"
                   >
                  <template v-slot:first>
                   <b-form-select-option :value=0>Select</b-form-select-option>
                 </template>
                </b-form-select>
              </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="6">
                <b-form-group
                 label-for="payment_status_id"
                >
                <template v-slot:label>
                 Payment Status
                </template>
                <b-form-select
                    plain
                    v-model="search.payment_status_id"
                    :options="paymentStatuses"
                    id="payment_status_id"
                   >
                  <template v-slot:first>
                   <b-form-select-option :value=0>Select</b-form-select-option>
                 </template>
                </b-form-select>
              </b-form-group>
              </b-col>
             <b-col lg="3" md="3" sm="3" xs="6">
              <b-form-group
                id="transction_id"
                label="Transction Id"
                label-for="transction_id"
              >
              <template v-slot:label>
                Transction Id
              </template>
                <b-form-input
                  id="transction_id"
                  v-model="search.transction_id"
                  placeholder="Enter Transction Id"
                ></b-form-input>
              </b-form-group>
              </b-col>
             <b-col lg="3" md="3" sm="3" xs="6">
              <b-form-group
                id="invoice_id"
                label="Invoice Id"
                label-for="invoice_id"
              >
              <template v-slot:label>
                Invoice Id
              </template>
                <b-form-input
                  id="invoice_id"
                  v-model="search.invoice_id"
                  placeholder="Enter Invoice Id"
                ></b-form-input>
              </b-form-group>
              </b-col>
              <b-col sm="12" md="3">
                  <br>
                  <b-button size="sm" variant="primary" @click="searchData"><i class="ri-search-line"></i> Search</b-button>
                  <b-button size="sm ml-1" variant="danger" @click="clearData"><i class="ri-close-line"></i> Clear</b-button>
                </b-col>
              </b-row>
          </b-card-text>
      </b-card>
  </div>
  <b-card class="mt-3">
    <b-card-title>
      <b-row>
        <b-col>
          <h4 class="card-title mb-0 pl-0">Invoice List</h4>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="has_permission('add_new_invoice')" size="sm" variant="info" @click="openAddNewModal()">Add New<i class="ri-add-fill"></i></b-button>
        </b-col>
      </b-row>
    </b-card-title>
      <b-row>
      <b-col>
        <b-overlay :show="loading">
            <div class="table-wrapper table-responsive">
              <table class="table table-striped table-hover table-bordered">
                <thead>
                  <tr style="font-size: 12px;">
                    <th scope="col" class="text-center">SL</th>
                    <th scope="col" class="text-center">Payment Date</th>
                    <th scope="col" class="text-center">Invoice Id</th>
                    <th scope="col" class="text-center">Transction ID</th>
                    <th scope="col" class="text-center">Subscription Plan</th>
                    <th scope="col" class="text-center">Grand Total</th>
                    <th scope="col" class="text-center">VAT Amount</th>
                    <th scope="col" class="text-center">Discount</th>
                    <th scope="col" class="text-center">Payable Amount</th>
                    <th scope="col" class="text-center">Payment Status</th>
                    <th scope="col" class="text-center">Payment Media</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                </thead>
                <template v-if="listData.length > 0">
                  <tbody v-for="(item, index) in listData" :key="index">
                    <tr style="font-size: 11px;">
                      <td scope="row" class="text-center">{{ index + pagination.slOffset }}</td>
                      <td class="text-center">{{ item.payment_date ? dDate(item.payment_date) : '' }}</td>
                      <td class="text-center">{{ item.invoice_id }}</td>
                      <td class="text-center">{{ item.transction_id }}</td>
                      <td class="text-center">{{ getSubscriptionPlanNameList(item.subcription_plan_id) }}</td>
                      <td class="text-right">{{ item.grand_total }}</td>
                      <td class="text-right">{{ item.vat_amount }}</td>
                      <td class="text-right">{{ item.discount }}</td>
                      <td class="text-right">{{ item.payable_amount }}</td>
                      <td class="text-center">
                        <span  v-if="item.payment_status" v-html="item.payment_status.name" :class="`badge text-white badge-pill badge-${item.payment_status.color_name}`" ></span>
                        <!-- <template>
                          <span class="badge badge-pill badge-info" v-if="item.payment_status_id === 1">Pending</span>
                          <span class="badge badge-pill badge-success" v-if="item.payment_status_id === 2">Success</span>
                          <span class="badge badge-pill badge-primary" v-if="item.payment_status_id === 3">Failed</span>
                          <span class="badge badge-pill badge-danger" v-if="item.payment_status_id === 4">Cancelled</span>
                        </template> -->
                      </td>
                      <td class="text-center">{{ getPaymentMediaNameList(item.payment_media_id) }}</td>
                      <td class="text-center">
                        <a v-tooltip="'Edit'" v-if="has_permission('edit_invoice')" href="javascript:" style="width: 20px !important; height: 20px !important; font-size:10px" class="action-btn edit" @click="editData(item)"><i class="ri-pencil-fill"></i></a>
                        <a v-tooltip="'Delete'" v-if="has_permission('delete_invoice')" @click="deleteConfirmation(item)" style="width: 20px !important; height: 20px !important; font-size:10px" href="javascript:" class="action-btn delete"><i class="ri-delete-bin-2-line"></i></a>
                      </td>
                    </tr>
                  </tbody>
                 </template>
                 <template v-else>
                    <tr>
                       <td colspan="12" class="notFound">Data Not Found</td>
                    </tr>
                 </template>
              </table>
            </div>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
    <b-modal id="modal-1" ref="editModal" size="lg" title="Invoice" centered :hide-footer="true">
      <Form @loadList="loadData" :editItem="editItem"/>
    </b-modal>
    <!-- pagination -->
    <div class="pagination-wrapper mt-4">
      <span>Showing {{ pagination.slOffset }} from {{ pagination.totalRows }} entries</span>
      <b-pagination
        size="sm"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        @input="searchData"
        />
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Form from './Form.vue'
import RestApi, { baseURL } from '@/config'
export default {
  components: {
    Form, flatPickr
  },

  data () {
    return {
      // pagination
      rows: 100,
      currentPage: 1,
      // form data
      search: {
        payment_date: '',
        subcription_plan_id: 0,
        payment_media_id: 0,
        payment_status_id: 0,
        transction_id: ''
      },
      value: '',
      listData: [],
      loading: false,
      editItem: '',
      subscriptionPlanList: [],
      paymentMediaList: [],
      paymentStatuses: [],
      flatPickrConfig: {
        maxDate: 'today',
        dateFormat: 'd-m-Y'
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.getSubscriptionPlanList()
    this.getPaymentMediaData()
    this.getStatusesWithGroups()
  },
  computed: {
  },
  methods: {
    openAddNewModal () {
      this.editItem = ''
      this.$refs.editModal.show()
    },
    editData (item) {
      this.editItem = JSON.stringify(item)
      this.$refs.editModal.show()
    },
    searchData () {
      this.loadData()
    },
    clearData () {
      this.search = {
        payment_date: '',
        subcription_plan_id: 0,
        payment_media_id: 0,
        payment_status_id: 0,
        transction_id: ''
      }
      this.loadData()
    },
    async loadData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      var result = await RestApi.getData(baseURL, 'api/v1/admin/ajax/get_invoice_list', params)
      if (result.success) {
        this.listData = result.data.data
        this.paginationData(result.data)
      }
      this.loading = false
    },
    async toggleActiveStatus (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/toggle_invoice_active_data', item)
      if (result.success) {
        this.$toast.success({ title: 'Success', message: result.message })
        this.loadData()
      }
      this.loading = false
    },
    getSubscriptionPlanList () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_subscription_plan_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.subscriptionPlanList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text }
            })
        } else {
          this.subscriptionPlanList = []
        }
      })
    },
    getPaymentMediaData () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_payment_media_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.paymentMediaList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text }
            })
        } else {
          this.paymentMediaList = []
        }
      })
    },
    getStatusesWithGroups () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_statuses_with_groups', null).then(response => {
        if (response.success) {
          if (response.status_groups) {
            this.paymentStatuses = this.makeSelectList(this.getStatuseByGroupCode(response.status_groups, 'payment_statuses'))
          }
        } else {
          this.paymentStatuses = []
        }
      })
    },
    getSubscriptionPlanNameList (Id) {
      const obj = this.subscriptionPlanList.find(el => el.value === Id)
      if (obj !== undefined) {
        return obj.text
      } else {
        return ''
      }
    },
    getPaymentMediaNameList (Id) {
      const Obj = this.paymentMediaList.find(el => el.value === Id)
      if (Obj !== undefined) {
        return Obj.text
      } else {
        return ''
      }
    },
    deleteConfirmation (item) {
      this.$swal({
        title: 'Are you sure to delete?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          // declare confirmed method to hit api
          this.deleteRole(item)
        }
      })
    },
    async deleteRole (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/delete_invoice_data', item)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.loadData()
      }
      this.loading = false
    }
  }
}
</script>
<style>
 .notFound {
   text-align: center;
 }
</style>
